* {
  box-sizing: border-box;
}

.nav {
  padding: 0;
  z-index: 100;
  height: 55px;
  color: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  border-radius: 0px 0px 0px 18px;
  display: flex;
  backdrop-filter: blur(3px);
  background-color: #11111180;
}

@media (orientation: portrait) {
  .nav {
    padding: 0;
    z-index: 100;
    height: 55px;
    left: 0;
    bottom: 0;
    top: auto;
    color: white;
    position: fixed;
    width: 100vw;
    border-radius: 0px;
    display: flex;
    justify-content: space-around;
    backdrop-filter: blur(3px);
    background-color: #1b1b1b80;
    #navItems {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }
}

nav ul {
  display: flex;
}

.nav a {
  color: inherit;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 7px;
  text-decoration: none;
  transition: all 0.2s ease;
  height: 70%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: fit-content;
}

.nav li .active {
  background-color: rgba(105, 105, 105, 0.301);
  border-radius: 10px;
}

.nav a:hover {
  background-color: rgba(73, 73, 73, 0.493);
  border-radius: 10px;
}
