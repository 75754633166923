@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");
@import url("https://fonts.googleapis.com/css?family=Roboto:400");

* {
  margin: 0;
  padding: 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  color: white;
  font-family: "Fira Mono";
}

body {
  width: 100vw;
  max-width: 100%;
  overflow: scroll;
  // overflow-x: hidden;
  // overflow-x: hidden;
  background-color: rgb(0, 0, 0);
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

html {
  width: 100%;
  max-width: 100%;
}
