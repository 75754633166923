.skillsContainer {
  display: flex;
  align-items: center;

  justify-content: center;
  flex-direction: column;
  // width: 100vw;
  // left: 0;
  // height: 100%;
  //   border-style: solid;
  //   border-width: 1px;
  //   border-color: rgb(211, 211, 211);
}

.skilltextright {
  display: flex;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  // height: fit-content;
  p {
    font-weight: 400;
    text-shadow: 1px 1px #3f3838;
    height: 100%;
    margin-top: 40px;
    font-size: 1rem;
  }
}

.skilltextcontainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  // margin: 70px;
  // margin-left: 10%;
  // margin-right: 10%;
  width: 85vw;
  // margin-bottom: 80px;
}

.Typewriter {
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 100%;
  flex-direction: row;
}

.skillTypewriter {
  width: 100vw;
  display: flex;
  // height: 220px;
  margin-bottom: 40px;
  justify-content: center;
  flex-wrap: wrap;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
    white-space: nowrap;
    font-weight: 600;
    width: 100%;
    height: fit-content;
    background-image: radial-gradient(#553c9a, #ee4b2b);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .Typewriter__wrapper {
    white-space: nowrap;
    font-size: 70px;
    font-weight: 600;
    background-image: radial-gradient(#553c9a, #ee4b2b);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .Typewriter__cursor {
    white-space: nowrap;
    font-size: 70px;
    font-weight: 600;
    background-image: radial-gradient(#553c9a, #ee4b2b);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
}

@media (orientation: portrait) {
  .skillTypewriter {
    margin-top: 40px;
    flex-direction: row;
    h1 {
      font-size: 30px;
    }
    .Typewriter__wrapper {
      font-size: 60px;
    }
    .Typewriter__cursor {
      font-size: 60px;
    }
  }
  .skilltextcontainer {
    flex-direction: column;
  }
}
