#tsparticles {
  position: fixed;
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  padding: 0;
  left: 0;
  top: 10;
  z-index: -100;
}

.App::-webkit-scrollbar {
  display: none;
}
