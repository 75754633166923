.fullbar {
  z-index: 5;
  // width: 80%;
  margin-right: 30px;
  background: rgba(27, 27, 27, 0.911);
  background-size: 400px 100%;
  border-radius: 2px 15px 15px 2px;
}

.skillbar {
  z-index: 6;
  background: linear-gradient(
    to right,
    rgb(28, 0, 36),
    rgba(121, 9, 61, 0.979)
  );
  // background-size: 400px 100%;
  border-radius: 2px 15px 15px 2px;
}

.skillitem {
  display: flex;
}

@media (orientation: portrait) {
  .fullbar {
    z-index: 5;
    width: 100%;
    // margin-right: 30px;
    background: rgba(27, 27, 27, 0.911);
    background-size: 400px 100%;
    border-radius: 2px 15px 15px 2px;
  }
}
