.container {
  width: 100vw;
  max-width: 100%;

  position: relative;
  // overflow-x: hidden;
  // padding-top: 54px;
  min-height: 100vh;

  // height: 100vh;
  // background-color: rgba(119, 119, 119, 0.384);
}

.content {
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // padding-top: 100px;
  min-height: 100vh;
}

.blurred {
  backdrop-filter: blur(2px);
  max-width: 100%;
  width: 100vw;
  position: fixed;
  height: 100vh;
}
