.languageButton {
  transition: all 0.2s ease;
  text-align: center;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  right: 0;
  border-width: 2px;
  border-color: rgba(90, 90, 90, 0.479);
  z-index: 101;
  backdrop-filter: blur(3px);
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 50%;
}

.languageButton:hover {
  background-color: rgb(49, 49, 49);
}

@media (orientation: portrait) {
  .languageButton {
    bottom: 50px;
  }
}
