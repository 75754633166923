.backgroundBar {
  position: absolute;
  background-color: #42424225;
  backdrop-filter: blur(8px);
  width: 100%;
  transform: skew(0deg, -4deg);
  width: 100vw;
  max-width: 100%;
  height: 70%;
  top: 15%;
  z-index: -10;
}

.meImage {
  min-width: 300px;
  max-width: 500px;
  width: 22%;
  height: auto;
}

#aboutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-family: "Roboto";
  min-width: fit-content;

  h1 {
    font-family: "Roboto", monospace;
    text-align: center;
    letter-spacing: 0.1rem;
    font-size: 60px;
    min-width: fit-content;
    font-weight: 600;
    background-image: radial-gradient(#553c9a, #ee4b2b);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }

  h2 {
    text-align: center;
    font-size: 20px;
    min-width: fit-content;
    letter-spacing: 0.2rem;

    font-weight: 600;
    background-image: radial-gradient(#553c9a, #ee4b2b);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  #aboutText {
    width: 45%;
    margin: 6%;
    p {
      font-weight: 400;
      text-shadow: 1px 1px #3f3838;
      height: 100%;
      margin-top: 40px;
      font-size: 1.2rem;
    }
  }
}

@media (orientation: portrait) {
  .meImage {
    min-width: 200px;
    max-width: 300px;
    width: 22%;
    height: auto;
  }

  .backgroundBar {
    display: none;
  }
  #aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;
    }
    #aboutText {
      width: 90%;
      margin-top: 7%;
    }
  }
}
