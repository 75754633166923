.contactcontent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    display: flex;
    margin-left: 20px;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .formheader {
    // font-size: larger;
    margin: 30px;
    h1 {
      font-family: "Roboto", monospace;
      text-align: center;
      letter-spacing: 0.1rem;
      font-size: 60px;
      min-width: fit-content;
      font-weight: 600;
      background-image: radial-gradient(#553c9a, #ee4b2b);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      margin-bottom: 30px;
    }
    h2 {
      text-align: center;
      font-size: 30px;
      min-width: fit-content;
      letter-spacing: 0.2rem;

      font-weight: 600;
      background-image: radial-gradient(#553c9a, #ee4b2b);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      margin-bottom: 30px;
    }
  }

  .mailwarningtext {
    color: red;
  }

  .contactform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    background-color: rgba(27, 27, 27, 0.411);
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    padding: 20px;

    .personinfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }

    .message {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      width: 100%;
      height: fit-content;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }
  }

  .sendresult {
    height: fit-content;
    border-radius: 12px;
    padding: 15px;
    z-index: 102;
    position: fixed;
    bottom: 3%;
    right: 3%;
    background-color: #313131;
  }

  input {
    padding: 13px;
    background-color: #313131;
    border-radius: 12px;
    color: #eee;
    font-size: 18px;
    width: 100%;
    margin-bottom: 10px;
  }
  .textarea {
    min-height: 80px;
    padding: 13px;
    // min-height: 300px;
    background-color: #313131;
    border-radius: 12px;
    border: 0;
    color: #eee;
    font-size: 18px;
    width: 100%;
  }

  .sendbtn {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #313131;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
  }
  .btndisabled {
    background: #31313154;
    color: #63636354;
  }

  .glow:before {
    content: "";
    background: linear-gradient(90deg, #8d0707, #ff00c8);
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
    filter: blur(3px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 12px;
  }

  .glow:active:after {
    background: transparent;
  }

  .glow:hover:before {
    opacity: 1;
  }

  .glow:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #313131;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
}

.othercontact {
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (orientation: portrait) {
  .contactcontent {
    .formheader {
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1.5rem;
      }
    }
    .contactform {
      // background-color: rgb(139, 9, 9);
      width: 90vw;
      padding: 10px;
      padding: 10px;
    }
  }
  .sendresult {
    right: auto;
    margin-right: 5%;
    margin-left: 5%;
    top: 3%;
  }

  .mailcontact {
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .xingcontact {
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
  }
}
