.logo {
  width: 100px;
  height: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.carouselcontainer {
  display: flex;
  // margin-top: 70px;
  margin-bottom: 50px;
  // background-color: rgba(15, 15, 15, 0.164);
  backdrop-filter: blur(7px);
  min-width: fit-content;
  position: relative;
  width: 100vw;
  height: 140px;
  overflow-x: hidden;
}

.carouselclass {
  display: flex;
  // display: none;
  justify-content: space-around;
  align-items: center;
  min-width: fit-content;
  width: inherit;
  height: inherit;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
  left: 0%;
  top: 0%;

  animation: primary 33.88s linear infinite;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.carouselclass2 {
  display: flex;
  // display: none;
  justify-content: space-around;
  align-items: center;
  min-width: fit-content;
  width: inherit;
  width: 100vw;
  height: inherit;
  position: absolute;
  overflow-x: hidden;
  left: 0%;
  top: 0%;

  animation: secondary 33.88s linear infinite;
}

@keyframes primary {
  from {
    left: 0%; //0
  }
  to {
    left: -100%; //-100
  }
}

@keyframes secondary {
  from {
    left: 100%; //100
  }
  to {
    left: 0%; //0
  }
}
